import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import pages
import Home from './pages/Home';
import About from './pages/About';
import SingleCocktail from './pages/SingleCocktail';
import Error from './pages/Error';
import Nfts from './pages/Nfts';
import MotionText from './components/MotionText';
import RoadmapPage from './pages/RoadMap';
// import components
import Navbar from './components/Navbar';
import HowToBuy from './pages/HowtoBuy';
function App() {
  return (
    <Router>
     
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='nfts' element={<Nfts />} />
        <Route path='/roadmap' element={<RoadmapPage />} />
        <Route path='/howtobuy' element={<HowToBuy />} />
        <Route path='cocktail/:id' element={<SingleCocktail />} />
        <Route path='*' element={<Error />} />
      </Routes>
    
    </Router>
  );
}

export default App;
