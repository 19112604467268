import React from 'react'
import { Link } from 'react-router-dom'
export default function MotionText() {
  return (
    <article className='moving_text_container'>
      <div className='moving_text-footer'>
      <h1>
      <marquee behavior="linear" direction="left">$COCK</marquee>
        <marquee behavior="linear" direction="left">$COCK</marquee>
        <marquee behavior="linear" direction="left">$COCK</marquee>
        <marquee className="md-screen" behavior="linear" direction="left">$COCK</marquee>
        <marquee className="md-screen" behavior="linear" direction="left">$COCK</marquee>
        <marquee className="md-screen" behavior="linear" direction="left">$COCK</marquee>
      
        </h1> 
      </div>
    </article>
  )
}
