import React from "react";
import { Link } from "react-router-dom";
import aboutImage from "../util/meme/WhatsApp_Image_2024-09-01_at_7.39.15_PM-removebg-preview.png";
import { faB } from "react-icons/fa";
import okxIcon from '../images/OKX_Logo.svg.png'
import bybitIcon from '../images/BYBIT.png'
import htxIcon from '../images/HTX-Logo.png'
import binanceIcon from '../images/binance1.png'
import mexcIcon from '../images/MEXC.png'
import bitmartIcon from '../images/bitmart.png'
import poloniexIcon from '../images/poloniex.png'
import bitgetIcon from  '../images/bitget.png'
export default function About() {
  return (
    <section className="about_about">
      <div className="about_container">
        <div className="about_image">
          <img src={aboutImage} alt="About Image" className="about_imageer" />
        </div>
        <div className="about_text">
          <h1 className="section-title">about $COCK</h1>
          <p>
          Once upon a time in the Solana Galaxy in a world of rugs, scams, and fake promises where people constantly seek new opportunities, the cats tried to save them all but some faded and others believed but soon enough they got corrupted the people got tired of searching for something to get them out the trenches, and soon started rotating and it got them nowhere.
         </p> 

         <p>
         The inspiration of bigcock all started with $200k loss in a political trump token which still hurt, but not just that, we all ape this token everyday looking for gem and we all have hit a few rugs here and there, some move but just a little so dies right after, this is what inspired bigcock.. it not just about me bigcock is for everyone that has lost money to 
         this rugs it is something that belongs to the community
         </p>
         <p>
         The people need something of some believe in, something to give them hope something in which they could just HODL. </p>

          <div className="action_buttons">

          {/* <button className="buy"></button> */}
          <Link to="https://pump.fun/7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump" >
          <button className="buy
            ">BUY $COCK</button>
          </Link>
{/*        
          <Link to="/nfts" >
          <button className="nfts
            ">NFTs</button>
          </Link> */}
           
          </div>
        </div>
      </div>

      <div className="available_networks">
        <div className="network_wrapper">
          <div className="about_text w-100  mt-50">
            <h1 className="section-title">networkS</h1>
            <label> <strong>$COCK will be available on major exchanges for trading <strong>SOON</strong> </strong> </label>
          </div>
          <div className="newtwork_container" >
            <ul className="networks">
              <li>
                <button>
                <img 
                 src={bybitIcon}
                />
                </button>
                </li>
                <li>
                <button>
                <img 
                 src={okxIcon}
                />
                </button>
                </li>
                <li>
                <button>
                <img 
                 src={htxIcon}
                />
                </button>
                </li>

                <li>
                <button>
                <img 
                 src={bitgetIcon}
                />
                </button>
                </li>

            </ul>

            <ul className="networks">
              <li>
                <button>
                <img 
                 src={binanceIcon}
                />
                </button>
                </li>
                <li>
                <button>
                <img 
                 src={bitmartIcon}
                />
                </button>
                </li>
                <li>
                <button>
                <img 
                 src={poloniexIcon}
                />
                </button>
                </li>

                <li>
                <button>
                <img 
                 src={mexcIcon}
                />
                </button>
                </li>

            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
