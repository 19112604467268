import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Chart extends Component {
  render() {
    const options = {
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: "TOKENOMICS Circulating Supply: 963,000,000 ",
      },
      data: [
        {
          type: "pie",
          startAngle: 75,
          toolTipContent: "<b>{label}</b>: {y}%",
          showInLegend: "true",
          legendText: "{label}",
          indexLabelFontSize: 16,
          indexLabel: "{label} - {y}%",
          dataPoints: [
            { y: 17, label: "Presale" },
            { y: 18, label: "Private Round" },
            { y: 10, label: "Public Round" },
            { y: 6, label: "Liquidity" },
            { y: 15, label: "Reward for analysis" },
            { y: 19, label: "Seed Round" },
            { y: 19, label: "Referrals" },
          ],
        },
      ],
    };
    return (
      <div className="chart-wrapper">
        <div className="chart">
          <CanvasJSChart
            options={options}
            /* onRef={ref => this.chart = ref} */
          />
          {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
          <div>
           
          </div>
        </div>
      </div>
    );
  }
}
export default Chart;
