import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import buyImage from "../images/buy.png";
import data from '../util/data.js';
import created from '../util/generated.js'
import { Reload } from "@rsuite/icons";
import { Download } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import { ChangeCircle } from "@mui/icons-material";
import { FaToggleOn, FaCirclePlus,FaCircleMinus, FaPlus, FaMinus, FaPlusMinus } from "react-icons/fa6";
import { Resizable, ResizableBox } from 'react-resizable';
import DragResizeContainer from 'react-drag-resize';
import html2canvas from "html2canvas";
import bg1 from "../images/hero-bg.png"
import bg2 from "../images/9205971.jpg"
import bg3 from '../images/5230354.jpg'



import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { flushSync } from "react-dom";

import Moveable from "react-moveable";
import Draggable from 'react-draggable';


export default function Meme() {
  const [selected, setSelected] = useState([])
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  let [getelement, setGetElement] = useState()
  let [size, setSize] = useState(150)
  const [bgClass, setbgClass] = useState("work-area")
  const [resize, setResize] = useState(true)
  let newbg = "work-area"

  const [move, setMove] = useState([])
  let newX = 0, newY = 0, startX = 0, startY = 0

  const bg = ["work-area", "work-area1", "work-area2", "work-area2"]

  var node = document.querySelector('.work-area');

  const exportRef = useRef();

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };
  const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;
    const newWidth = element.scrollWidth - element.clientWidth;
    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }
    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
    html.style.width = null;
    body.style.width = null;


  };


  const download = (dataUrl, _Link) => {
    console.log(dataUrl, _Link)
  }
  let i = -1
  let a = -1;
  let j = 150
  const workArea = document.querySelector(".work-area")



  function incPage() {
    if (i < 4) {
      i++;
      newbg = bg[i]
      //console.log(newbg)
      setbgClass([bg[i]])
      //load next page in body using AJAX request

    } else if (i = bg.length) {
      i = -1;
    }

  }

  function incWidth(getelement) {
    // const styles =  _element.currentTarget.style
    if (size < 400) {
      size += 10;
      console.log(size)
      setSize(size += 10)
      // console.log(getelement)
    
      getelement.width = size + "px"
    getelement.height = size + "px"
      //console.log(newbg)
      // setbgClass([bg[i]])
      //load next page in body using AJAX request

    } else if (size = 400) {
      // // j = -10;
     getelement.width = 400+ "px"
      getelement.height = 400 + "px"
    }

  }


  function decWidth(getelement) {
    // const styles =  _element.currentTarget.style
    console.log(getelement)
    if (size < 400) {
      size -= 10;
      setSize(size -=10)
      console.log(size)
      // console.log(getelement)
    
     getelement.width = size + "px"
      getelement.height = size + "px"
      //console.log(newbg)
      // setbgClass([bg[i]])
      //load next page in body using AJAX request

    } else if (size == 50) {
      // j = -10;
      getelement.width = 50+ "px"
    getelement.height = 50 + "px"
    }

  }

  //  console.log(selected)
  return (
    <section className="meme_section">
      <h1 className="section-title">MEME GENERATOR</h1>
      <div className="meme_container">
        <div className="memefield">
          
          <div className="zoomer">
          <div > <span>Zoom out</span>
          <FaCircleMinus 
            onClick={() =>{
              setResize(false)
              decWidth(getelement)

            }}
          className="zoom"
           
          /> 
          </div>
          <div><FaCirclePlus className="zoom" 
              onClick={() =>{
                setResize(true)
                incWidth(getelement)
                
              }}
          /> <span>Zoom in</span> </div>
          {/* <> */}
          {/* <span>zoom in <FaCirclePlus className="zoom" /></span></> */}
          </div>
           
          
          <div className={bgClass} ref={exportRef}>

            {
              selected.map((select, index) => {
                //  console.log(select.position)
                return (
                  <Draggable>
                    <div id={index}
                    // onClick={(e) =>{
                    //   decWidth(e)
                    // }}

                      onClick={(e) => {
                        setGetElement(e.currentTarget.style)
                        resize ? incWidth(e.currentTarget.style) : decWidth(e.currentTarget.style) 

                        //   let initial = 150
                        //   let i = 150
                        // //  e.currentTarget.style.resize = "both"
                        // //  e.currentTarget.style.position  = "icky"
                        //  const styles =  e.currentTarget.style
                        //  const width = e.currentTarget.style.width
                        //  const height = e.currentTarget.style.height

                        //  console.log(e.currentTarget)


                        // if(initial < 400){
                        //   initial += 10
                        //   styles.width = initial + "px"
                        //   styles.height = initial + "px"
                        // }
                        // else if(initial = 400){
                        //   initial -= 10
                        //   styles.width = initial + "px"
                        //   styles.height = initial + "px"
                        // }

                      }}

                      className="resizeable-box" >

                      <img
                        src={select.url}
                        alt={select.name}
                      />
                    </div>
                  </Draggable>
                )
              })
            }
          </div>
         <p style={{"textAlign":"center"}} > Hint: <small >click on meme to zoom/resize</small> </p> 
        </div>
        <div className="stickerfield">
          <div className="meme-title">
            <h4>
              Create Your Meme
            </h4>
          </div>

          <div className="sticker-options">
            {
              data.map((sticker, index) => {
                // console.log(sticker.url)
                return (
                  <div className="sticker" id={index}>
                    <img
                      src={sticker.url}
                      alt={sticker.name}
                      onClick={() => {
                        const newSelected = []
                        newSelected.push(sticker)
                        const allSelected = selected.concat(newSelected)
                        setSelected(allSelected)
                      }} />
                  </div>
                )
              })
            }

          </div>
          {/* <div className="viewall">
            <Link to="#">
              <u>View all stickers</u>
            </Link>
          </div> */}
          <div className="sticker_action_buttons">
            <div >
              <button className="changebg"
                onClick={() => {
                  incPage()
                  console.log(newbg)
                  setbgClass(newbg)
                  //  console.log(workArea)
                }}
              > <ChangeCircle className="action-icons" />  <span> Change Background</span> </button>
            </div>
            <div  >
              <button

                onClick={() => {
                  console.log(exportRef.current)
                  exportAsImage(exportRef.current, "cock")
                }} className="download
            "> <Download className="action-icons"

                /> <span>Download</span> </button>
            </div>
            <div  >
              <button className="clear
            "
                onClick={() => { setSelected([]) }}
              > <Clear className="action-icons" /> <span>Clear</span> </button>
            </div>
            {/* <Link to="/nfts" >
              <button className="nfts
            ">NFTs</button>
            </Link> */}
          </div>
        </div>


      </div>
      <h1 className="section-title">GENERATED MEMES</h1>
      <div className="created">

        {
          created.map((creat, index) => {
            return (
              <div id="">
                <img
                  src={creat.url}
                  alt={creat.name}
                />
              </div>
            )
          })
        }
      </div>
    </section >
  );
}
