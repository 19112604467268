import React from "react"
import { FaTelegramPlane  } from "react-icons/fa";
import { FaTwitter  } from "react-icons/fa";
import { FaDiscord  } from "react-icons/fa";
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <section className="footer_section">
      <div className="footer_container">
        <div className="footer_text">
          <h1 className="section-title">
             $COCK
          </h1>
          <p>
          Big $cock signify a new opportunity and we are here everyday on vc with the community, to see it come alive
          </p>

          <div className="action_buttons">
          <Link
              to={"https://x.com/bigcockonsol?s=21&t=Ombm56ZgnSABzIyXhRqdSQ"}
            >
              {" "}
              <FaTwitter className="sicons" />
            </Link>
            <Link to={"https://t.me/BIGCOCKISBIG"}>
              <FaTelegramPlane className="sicons" />
            </Link> 
            <Link>
            <FaDiscord className="sicons"/> 
            </Link>
           
           
         </div>
        </div>
      </div> 
    </section>
  );
}
