import React from "react";
import { Steps } from "rsuite";
import tokenImg from '../images/buyn.png'

import { Chart } from "react-google-charts";
export default function () {


   const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];
  const options = {
    title: "TokenNomics",
    is3D: false,
  };
  return (
    <section className="how-to-buy-section">
      <div className="roadmap-container">
        
        <div className="token-img"> 
         <img 
         src={tokenImg}
         alt="tokenImg"
         />
        </div>
        <div className="token-text">
          <h4>TOKENOMICS:</h4>
          <div className="text-body">
            <label>Total Supply: <strong>5,0464,52 $COCK</strong> </label>
           <br />
            <label>Liquidity: <strong>100%</strong> </label>
            <br />
            <label>Presale: <strong>No Presale</strong> </label>
            <br />
       
          </div>
          
        </div>
      </div>
    </section>
  );
}
