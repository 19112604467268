import peacenop from './meme/65d5256d23f4f.jpeg'
import enter from './meme/ed7650b63b0fee7b61fe8242a9207d22-removebg-preview.png'
import cocof from './meme/chicken-coffee.gif'
import cocspek from './meme/276933391768f3e7051f5b25a99f789c-removebg-preview.png'
import coctum from './meme/15cbbdd3b021a95a499737b6c658fc96-removebg-preview.png'
import balloon from './meme/balloon.webp'
import airleft from './meme/airpodsLEFT.webp'
import airleftright from './meme/airpodsLEFTandRIGHT.webp'
import aflag from './meme/americanflag.webp'
import blush from './meme/blush.webp'
import burger from './meme/burger.webp'
import camera from './meme/camera.webp'
import chineeseflag from './meme/chineeseflag.webp'
import chromehat from './meme/chromahat.webp'
import cigarette from './meme/cigarette.webp'
import coffee from './meme/coffee.webp'
import coffeecup from './meme/coffeecup.webp'
import cophat from './meme/cophat.webp'
import cowboyhat from './meme/cowboyhat.webp'
import diabloHorns from './meme/diabloHorns.webp'
import donut from './meme/donut.webp'
import dunks from './meme/dunks.webp'
import fishingRod from './meme/fishingRod.webp'
import fork from './meme/fork.webp'
import goldenChain from './meme/goldenChain.webp'
import guitar from './meme/guitar.webp'
import halo from './meme/halo.webp'
import iphone from './meme/iphone.webp'
import kingcrown from './meme/kingcrown.webp'
import knife from './meme/knife.webp'
import lazereyes from './meme/lazereyes.webp'
import m4a16  from './meme/m4a16.webp'
import makeAmerican from './meme/makeAmericaGreatAgain.webp'
import mcdees from './meme/mcdees.webp'
import mcfreepotatoe from './meme/mcfreepotatoe.webp'
import money from './meme/money.webp'
import mustaches from './meme/mustaches.webp'
import redbull from './meme/redbull.webp'
import redEye from './meme/redEye.webp'
import rolex from './meme/rolex.webp'
import rubinksCube from './meme/rubiksCube.webp'
import smile from './meme/smile.webp'
import starwarssword  from './meme/starWarsSword.webp'
import sword from './meme/sword.webp'
import tableKnife from './meme/tableKnife.webp'
import tootbrush from './meme/toothbrush.webp'
import trident from './meme/trident.webp'
import vape from './meme/vape.webp'
import vipers from './meme/vipers.webp'
import cocksalute from './meme/WhatsApp_Image_2024-09-01_at_7.39.15_PM-removebg-preview.png'
import cocksalute1 from './meme/taaft.com-the-image-generator-by-danishahmadkhan-1725265376-removebg-preview.png'
import cocksalute2 from './meme/this-removebg-preview.png'
import cocksalute3 from './meme/this2.png'
import cocksalute4 from './meme/this3.png'

export default [

  {
    "id":1,
    "name": "coctum",
    "url":cocksalute
  },
  {
    "id":1,
    "name": "cocspek",
    "url":cocksalute1
     
  },
  {
    "id":1,
    "name": "coctum",
    "url":cocksalute2
     
  },
  
  {
    "id":1,
    "name": "coctum",
    "url":cocksalute3
     
  },
  {
    "id":1,
    "name": "coctum",
    "url":cocksalute4
     
  },
  {
    "id":1,
    "name": "coctum",
    "url":coctum
     
  },
  {
    "id":1,
    "name": "cocspek",
    "url":cocspek
     
  },
 

  {
    "id":1,
    "name": "cowboy",
    "url":cowboyhat
     
  },
  {
      "id":1,
      "name": "camera",
      "url":camera
       
    },
    {
      "id":1,
      "name": "chromehat",
      "url":chromehat
       
    },



    {
      "id":1,
      "name": "cocf",
      "url":cocof
       
    },

    {
      "id":1,
      "name": "coffee",
      "url":coffee
       
    },

    {
      "id":1,
      "name": "cowboy",
      "url":enter
       
    },

    {
      "id":1,
      "name": "cowboy",
      "url":peacenop
       
    },
    {
        "id":1,
        "name": "cigarate",
        "url":cigarette
         
      },
      {
        "id":1,
        "name": "coffeecup",
        "url":coffeecup
         
      },


    {
      "id":1,
      "name": "airpodLEFT",
      "url":airleft
       
    },


    {
      "id":1,
      "name": "cophat",
      "url":cophat
       
    },
    {
        "id":1,
        "name": "diablohorns",
        "url":diabloHorns
         
      },
      {
        "id":1,
        "name": "donut",
        "url":donut
         
      },

    {
        "id":1,
        "name": "airpodsLEFTandRIGHT",
        "url":airleftright
         
      },
      {
        "id":1,
        "name": "americanflag",
        "url":aflag
         
      },


      
      {
        "id":1,
        "name": "balloon",
        "url":balloon
         
      },
      
      {
        "id":1,
        "name": "blush",
        "url":blush
         
      }
    ,




    {
      "id":1,
      "name": "dunks",
      "url":dunks
       
    },
    {
        "id":1,
        "name": "knife",
        "url":knife
         
      },
      {
        "id":1,
        "name": "kingcrown",
        "url":kingcrown
         
      },

      {
        "id":1,
        "name": "guitar",
        "url":guitar
         
      },
      {
          "id":1,
          "name": "fork",
          "url":fork
           
        },
        {
          "id":1,
          "name": "fishing",
          "url":fishingRod
           
        },

    {
      "id":1,
      "name": "burger",
      "url":burger
       
    },

    {
      "id":1,
      "name": "lazereyes",
      "url":lazereyes
       
    },
    {
        "id":1,
        "name": "m4a16",
        "url":m4a16
         
      },


      {
        "id":1,
        "name": "iphone",
        "url":iphone
         
      },
      {
          "id":1,
          "name": "halo",
          "url":halo
           
        },
        {
          "id":1,
          "name": "goldenchain",
          "url":goldenChain
           
        },


      {
        "id":1,
        "name": "mcdees",
        "url":mcdees
         
      },

      {
        "id":1,
        "name": "chineeseflag",
        "url":chineeseflag
         
      },

      {
        "id":1,
        "name": "monet",
        "url":money
         
      },
      {
          "id":1,
          "name": "makeAmerica",
          "url":makeAmerican
           
        },
        {
          "id":1,
          "name": "rolex",
          "url":rolex
           
        },


        {
          "id":1,
          "name": "sword",
          "url":sword
           
        },
        {
            "id":1,
            "name": "redEye",
            "url":redEye
             
          },
          {
            "id":1,
            "name": "startwar",
            "url":starwarssword
             
          },


      {
          "id":1,
          "name": "mustaches",
          "url":mustaches
           
        },
        {
          "id":1,
          "name": "smile",
          "url":smile
           
        },


        {
          "id":1,
          "name": "redbull",
          "url":redbull
           
        },
        {
          "id":1,
          "name": "rubinkscube",
          "url":rubinksCube
           
        },
        {
          "id":1,
          "name": "tootbrush",
          "url":tootbrush
           
        },

        {
          "id":1,
          "name": "tableknife",
          "url":tableKnife
           
        },

        {
          "id":1,
          "name": "viper",
          "url":vipers
           
        },

        {
          "id":1,
          "name": "mcfree",
          "url":mcfreepotatoe
           
        },
        {
          "id":1,
          "name": "trident",
          "url":trident
           
        },
        {
          "id":1, 
          "name": "vape",
          "url":vape
           
        },


]