import React from 'react'
import SearchForm from '../components/SearchForm'
import Hero from '../components/Hero'
import MotionText from '../components/MotionText'
import About from '../components/About'
import Buy from '../components/Buy'
import HowTo from '../components/HowTo'
import Chart from '../components/Chart'
import Roadmap from '../components/Roadmap'
import Join from '../components/Join'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Meme from '../components/Meme'
export default function Home() {
  return (
    <main>
      <Navbar />
      <Hero  />
      <MotionText />
      <About />
      <MotionText />
      <Buy />
      <HowTo/>
      {/* <Chart /> */}
      <Roadmap />
      <Meme />
      <Join />
      <Footer />
      <MotionText />
    </main>
  )
}
