import React from "react";
import { Link } from "react-router-dom";
import { FaWallet, FaSpinner, FaExchangeAlt } from "react-icons/fa";
import Navbar from '../components/Navbar'

import { FaThumbsUp, FaLongArrowAltRight } from "react-icons/fa";
export default function HowTo() {
  return (
    <section className="how-to-buy-section">
      <div className="how-to-container">
        <h1 className="section-title">
          HOW TO BUY ON <Link
          className="nav-link"
          to="https://pump.fun/7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump
">
          PUMP.FUN 
          </Link> <br />
        </h1>

        <div className="how_to_text">
          <div>
            <strong id="line">
              CREATE WALLET & CONNECT
              <span>
                <hr />
              </span>
              <FaWallet className="how-to-icon" />
            </strong>
            <p>
            
            <FaLongArrowAltRight />  The easiest way to buy $cock is on PUMP.FUN.
            <br/>  
          
            <FaLongArrowAltRight />   Connect your phantom wallet.  
            <br/>
            <FaLongArrowAltRight />    If you don’t have one simply download phantom extension to your chrome browser, create new wallet and  save seed phrase.
             <br/>
             <FaLongArrowAltRight />  Visit: <Link
          className="nav-link"
          to="https://pump.fun/7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump
">
          PUMP.FUN 
          </Link> to connect your phantom.
          <br />

            </p>
          </div>
          <div>
            <strong id="line">
              FUND WALLET AND BUY
              <span>
                <hr />
              </span>
              <FaSpinner className="how-to-icon" />
            </strong>
            <p>
            <FaLongArrowAltRight />   Fund your wallet, connect to pump.fun and  place a buy trade

            </p>
          </div>
          <div>
            <strong id="line">
              SWAP ON TELEGRAM BOT
              <span>
                <hr />
              </span>
              <FaExchangeAlt className="how-to-icon" />
            </strong>
            <p>
            <FaLongArrowAltRight />  Another alternative is using Solana telegram trading  bot.
           <br /> <FaLongArrowAltRight />  Create a new wallet or import existing wallet address by putting in the seed phrase.
           <br /> <FaLongArrowAltRight />  Paste the contract address 
           <br /> <strong id="addre">
           7LT4tdbMfW8kosYi3EKistDDU5D
           SMAPbtEoA5Z6upump
            </strong> 
            <br /> and enter the amount of sol you want to swap </p>
          </div>
          {/* <div>
            <strong id="line">
              03
              <span>
                <hr />
              </span>
            </strong>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
              repudiandae architecto qui adipisci in officiis, aperiam sequi
              atque perferendis eos, autem maiores nisi saepe quisquam hic odio
              consectetur nobis veritatis quasi explicabo obcaecati doloremque?
              Placeat ratione hic aspernatur error blanditiis?
            </p>
          </div>*/}
        </div>
      </div>
    </section>
  );
}
