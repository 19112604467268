import React from "react";
import HowTo from "../components/HowTo";
import Navbar from "../components/Navbar";
import MotionText from "../components/MotionText";
export default function HowToBuy() {
  return (
    <>
    <Navbar />
    <section className="section about-section">
       <HowTo />
    </section>
    <MotionText />
    </>
  );
}
