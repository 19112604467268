import React, { useState, useRef } from "react";
import Loading from "./Loading";
import bigcock_hero from "../images/cock.png";
import { useGlobalContext } from "../context";
import { Message } from "rsuite";
import { Link } from "react-router-dom";

export default function Hero() {
  const {statusOn, setstatusOn} = useState("no_display")
  const { cocktails, loading } = useGlobalContext();
  const {mounted, setIsmounted} = useRef(false)

  if (loading) {
    return <Loading />;
  }

  const handleCopy = () =>{
    const inputField = document.querySelector(".inputAddress")
    const inputFieldValue =  inputField.value
    const addressBtn =  document.querySelector(".addressBtn")
    // copyText.select();
    // copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(inputFieldValue);
    addressBtn.textContent ="Address Copied!"
    addressBtn.style.backgroundColor = "#fca82b"

    setTimeout(() =>{
      addressBtn.textContent ="Copy Address"
    addressBtn.style.backgroundColor = "rgb(180, 40, 40)"

    }, 3000)

  }
  return (
    <div className="container fade-in-section ">
      <div className="text_container">
        <header>
          <h1>$COCK</h1>
        </header>
        <div className="text_body">
          <p>
            {" "}
            Some of you know me as the chicken but my creator Mr. Dozier gave me
            the name <span>BIG COCK.</span>
            <br />
            <br />
            <strong className="default_text_color">
              TICKER IS COCK ON PUMP.{" "}
            </strong>
          </p>
        </div>
        
        <div className="actions">
          <div className="address">
            <input
            className="inputAddress"
              type="text"
              value={"7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump"}
            />
            <span>
              <button className="addressBtn" onClick={handleCopy} >Copy Address</button>
             
            </span>
          </div>
          
          <Message id="alert" type="info" className="no_display" >
                <strong> $COCK </strong> Address Copied!     
              </Message>
          <div className="action_buttons">
          <Link to="https://pump.fun/7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump" >
          <button className="buy
            ">BUY $COCK</button>
          </Link>
            {/* <Link to="/nfts" >
          {/* <button className="nfts
            ">NFTs</button>
          </Link>  */} 
            

          </div>
        </div>
      </div>

      <div className="image_container">
        <div className="image_wrapper">
          {/* <img
              src={zImage}
              alt="cocktail db logo"
              className="hero_image"
            /> */}
          <img
            src={bigcock_hero}
            alt="cocktail db logo"
            className="hero_image"
          />
        </div>
      </div>
    </div>
  );
}
