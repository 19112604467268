import React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Join() {
  return (
    <section className="join_section">
      <div className="join_container">
        <div className="join_text">
          <h1 className="section-title">JOIN OUR COMMUNITY</h1>
          <div id="pcon">
          <p>
          Become part of a vibrant $Cock and join our growing community that’s all about transparency, fairness, and collective success. 
          By joining our community, you’ll stay ahead with the latest updates, participate in exciting discussions, and connect with like-minded individuals who are passionate about $Cock and the future of cock investors.
          </p>
          <p>
          Whether you’re a seasoned investor or new to the 
          crypto world, our community is here to support and grow with you. Don’t miss out—join us today and be part of something big!
         </p>
         </div>
          <div className="action_buttons">
           <Link to="https://t.me/BIGCOCKISBIG" >
          <button id="joinbtn" className="buy 
            ">JOIN NOW</button>
          </Link>
       
{/* 
            <Link to={"https://t.me/BIGCOCKISBIG"}>
              <FaTelegramPlane className="sicons" />
            </Link>
            <Link
              to={"https://x.com/bigcockonsol?s=21&t=Ombm56ZgnSABzIyXhRqdSQ"}
            >
              {" "}
              <FaTwitter className="sicons" />
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}
