import peacenop from './meme/65d5256d23f4f.jpeg'
import cocof from './meme/WhatsApp Image 2024-09-03 at 11.33.06 AM.jpeg'
import cock1 from './meme/this2.png'
import cock2 from './meme/taaft.com-the-image-generator-by-danishahmadkhan-1725265376.png'
import cock3 from './meme/WhatsApp_Image_2024-09-01_at_7.39.15_PM-removebg-preview.png'

export default [
    {
        "id":2,
        "name":"cockme",
        "url":cock3
    },
    {
        "id":2,
        "name":"cockme",
        "url":cocof
    },
    {
        "id":2,
        "name":"cockme",
        "url":cock1
    },

    // {
    //     "id":2,
    //     "name":"cockme",
    //     "url":cock2
    // },
    // {
    //     "id":2,
    //     "name":"cockme",
    //     "url":cock1
    // },

    // {
    //     "id":2,
    //     "name":"cockme",
    //     "url":cock2
    // }
]