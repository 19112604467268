import React from "react";
import { Line, Circle } from 'rc-progress';
import left from '../images/comin.jpg'
import bigcock_hero from "../images/cock-nft.png";
import { Link } from "react-router-dom";
export default function Nfts() {
  return (
    <section className=" nft-section">
       <div className="nfts">
        <div className="nfts-container">
          <div className="right">
            
             <img
             src={bigcock_hero}
             alt="right"
             />
           <Link 
           to='/'>
            <button>Home</button>
           </Link>
         </div>
         <div className="middle">
              <h4>
                WATCH OUT FOR 
              </h4>
              <h1>
                $COCK NTFs
              </h1>
            </div>
         {/* <div className="left">
            <img
             src={left}
             alt="left"
            />
         </div> */}

        </div>
      
       </div>
    </section>
  );
}
