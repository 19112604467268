import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/bigcock.png";
import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

export default function Navbar() {
  const handleToggle = () => {
    const navbar = document.querySelector(".nav-links");
    const hide_menu = document.querySelector(".hide_menu");
    const show_menu = document.querySelector(".show_menu");
    navbar.style.display = "block";
    navbar.style.transition = "4s";
    hide_menu.style.visibility = "visible";
    show_menu.style.display = "none";
  };

  const handleToggleHide = () => {
    const navbar = document.querySelector(".nav-links");
    const hide_menu = document.querySelector(".hide_menu");
    const show_menu = document.querySelector(".show_menu");
    navbar.style.display = "none";
    navbar.style.transition = "4s";
    hide_menu.style.visibility = "hidden";
    show_menu.style.display = "block";
  };
  return (
    <nav className="navbar">
      <div className="nav-center">
        <Link to="/" className="hover_effects">
          <img src={logo} alt="cocktail db logo" className="logo" />
        </Link>

        <div className="toggler">
          <FaBars className="show_menu toggler_icon" onClick={handleToggle} />
          <FaTimes className="hide_menu toggler_icon" onClick={handleToggleHide} />
        </div>
        <ul className="nav-links">
          <li>
            <Link to="/" className="nav-link">
              {" "}
              Home{" "}
            </Link>
          </li>
          <li>
            <Link to="/about" className="nav-link">
              {" "}
              About
            </Link>
          </li>
          <li>
            <Link to="/howtobuy" className="nav-link">
              {" "}
              How To Buy
            </Link>
          </li>
          <li>
            <Link to="/nfts" className="nav-link">
              {" "}
              NFTs HOLDERS
            </Link>
          </li>
          <li>
            <Link to="https://t.me/BIGCOCKISBIG" className="nav-link">
              {" "}
              Community
            </Link>
          </li>
          <div className="socials">
            <li>
              <Link to="https://t.me/BIGCOCKISBIG">
                {" "}
                <FaTelegramPlane className="icon_wrapper" />
              </Link>
            </li>
            <li>
              <Link to="https://x.com/bigcockonsol?s=21&t=Ombm56ZgnSABzIyXhRqdSQ">
                {" "}
                <FaTwitter className="icon_wrapper" />{" "}
              </Link>
            </li>
            <li>
              <Link to="https://t.me/BIGCOCKISBIG">
                {" "}
                <FaDiscord className="icon_wrapper" />{" "}
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}
