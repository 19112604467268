import React from "react";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MotionText from "../components/MotionText";
export default function About() {
  return (
    <>
        <Navbar />
    <section className="section about-section">
      <h1 className="section-title">about us</h1>
      <p>
      Once upon a time in the Solana Galaxy in a world of rugs, scams, and fake promises where people constantly seek new opportunities, the cats tried to save them all but some faded and others believed but soon enough they got corrupted the people got tired of searching for something to get them out the trenches, and soon started rotating and it got them nowhere.
        The people need something of some believe in, something to give them hope something in which They could just HODL.
      </p>
    </section>
    <MotionText />
    </>
  );
}
