import React from "react";
import { Link } from "react-router-dom";
import buyImage from "../util/meme/WhatsApp Image 2024-09-03 at 11.33.06 AM.jpeg";
export default function Buy() {
  return (
    <section className="buy_section">
      <div className="about_container">
        <div className="about_image">
          <img src={buyImage} alt="About Image" className="about_imageer" />
        </div>
        <div className="about_text">
          <h1 className="section-title">MARKETING STRATEGY
          </h1>
          <p>

            For full transparency, Our marketing strategy for $Cock
            we are leveraging key channels such as social media, influencer partnerships, and community engagement through our Telegram channel to create awareness about $Cock and drive adoption.
          </p>
          <p>
            We launched on Pump platform and their process helps
            to maintain safe launches by securing the liquidity and trust in our project. At $61K market cap the $12k in liquidity rolls over to Raydium Dex and it will keep our users safe to see the biggest $Cock pump for diamond holders.
          </p>

          <div className="action_buttons">

            <Link to="https://pump.fun/7LT4tdbMfW8kosYi3EKistDDU5DSMAPbtEoA5Z6upump" >
              <button className="buy
            ">BUY $COCK</button>
            </Link>
            {/* <Link to="/nfts" >
              <button className="nfts
            ">NFTs</button>
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}
