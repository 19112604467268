import React from "react";
import Roadmap from "../components/Roadmap";

export default function RoadmapPage() {
  return (
    <section className="section about-section">
        <Roadmap />
    </section>
  );
}
